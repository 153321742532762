import './contactmeform.css';

function ContactMeForm() {
    return (
        <div class="container">
            <form action="https://formsubmit.co/erikh3213@gmail.com" method="POST">
                <h3>Escríbeme</h3>
                <input type="text" id="name" name="name" placeholder="Nombre" required></input>
                <input type="text" id="phone" name="phone" placeholder="# de Telefono" required></input>
                <input type="text" id="email" name="email" placeholder="Correo Electrónico" required></input>
                <textarea id="message" name="message" rows="4" placeholder="Como podemos ayudarte?" required></textarea>
                <button type="submit">Enviar</button>
            </form>
        </div>
    );
}

export default ContactMeForm;